import { getRequest,downloadRequest} from "@/libs/axios";
// 数据字典查询
export const getDictList = params => {
  return getRequest("/yethan/dict/tableInfo/listPage", params);
};
//大类导出
export function exportDictExcel(data={}) {
    return downloadRequest('/yethan/dict/tableInfo/exportExcel',data);
}
// 字典导出dbf
export function exportDictDbf(data={}) {
    return downloadRequest('/yethan/dict/tableInfo/exportDbf',data);
}

//详情导出dbf
export function exportDictDetailDbf(data={}) {
    return downloadRequest('/yethan/dict/tableInfo',data);
}