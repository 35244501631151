<!-- 字典数据 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style scoped>
  .font-blue {
    cursor: pointer;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import BaseHeader from "@/components/base-header";
  import {
    getDictList,
  } from "@/api/admin/base/dict.js"

  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      BaseHeader,

    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "数据字典",
            active: true
          }
        ],
        tableList: [],
        dicts: [],
        module: "DICT",
        select:{
          keyParam:"bm"
        },
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
        },
      };
    },
    methods: {
      getList() {
        getDictList(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList()
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getlistByParam() //渲染数据的函数
      },
      // 条件查询
      getlistByParam() {
        getDictList({
          ...this.pageData,
          ...this.select
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total;
          }
        })
      },

      getDictDetail(name) {
        this.$router.push({
          path: '/admin/baseDataDetail',
          query: {
            dictName: name
          }
        })
      }
    },
    mounted() {
      this.getList()
    }
  };
</script>

<template>
  <Layout>
    <BaseHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <input placeholder="关键字" v-model="select.keyWord" class="h30 form-control border-blue mr-2"
                  maxlength="50" style="width: 156px;" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: '字典表信息导出', type: 'xlsx', module:module,condition:select })"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: '字典表信息导出', type:'dbf', module:module,condition:select})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width:5%">序号</th>
                    <th style="width:25%">表名称</th>
                    <th style="width: 15%;">表简介</th>
                    <th>说明</th>
                    <th style="width:10%">数据量</th>
                    <th style="width:10%">字段数</th>
                    <th style="width: 6%;">修改人</th>
                    <th style="width: 10%;">修改时间</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>
                      <div class="font-blue" @click="getDictDetail(obj.bm)">{{obj.bm}}</div>
                    </td>
                    <td>{{obj.bjj}}</td>
                    <td> {{obj.bsm}}</td>
                    <td>{{obj.bsjl}}</td>
                    <td>{{obj.bzds}}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{timeSlice(obj.modifyTime)}}</td>
                  </tr>

                </tbody>
              </table>

            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>

            </div>
          </div>
        </div>
      </div>


    </div>

  </Layout>
</template>
